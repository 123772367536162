/* eslint-disable space-before-function-paren */
import { AxiosError } from 'axios'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteData } from '../../components/IDB/idb'
import { useAuth } from '../../hooks/UseAuth'
import { api } from '../../services/api'
import { updateBookmark } from '../../services/bookmarks.services/bookmark'
import { setAuthorizationHeader } from '../../services/interceptors'
import { subscriber } from '../../services/tokenUpdateService'
import { getUserDetails } from '../../services/users.service/getUser'
import { Subscription } from '../../types/accountTypes'
import { BookmarkType } from '../../types/authTypes'
import { deleteStorage, getStorageDate, setStorageData } from '../../utils/localStorage'
import { notifyUser } from '../../utils/notify'

interface User {
  last_name: string
  email: string
  permissions: string[]
  roles: string[]
  first_name: string
  is_subscribe: boolean
  hasPaymentMethod: boolean
  image_url: string
  is_training_completed: boolean
  videoBookmark: BookmarkType | null,
  stripe_customer_id:string | null,
  is_training_enabled:boolean,
  subscription:Subscription
}

interface SignInCredentials {
  email: string
  password: string
}

interface AuthContextData {
  signIn: (credentials: SignInCredentials) => Promise<void | AxiosError>
  signOut: () => void
  user: User
  isAuthenticated: boolean
  loadingUserData: boolean,
  setUser: (newUser: User | null) => void;
  updateBookmarkVideo: () => Promise<void | AxiosError>
  timeStamp: string
  shipLimit: string,
  sidebarOpen:boolean,
  setSidebarOpen:any
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [user, setUser] = useState<User | null>()
  const [loadingUserData, setLoadingUserData] = useState(false)
  const [timeStamp, setTimeStamp] = useState<string>('00:00')
  const [shipLimit, setShipLimit] = useState<string>('3')
  const [doLogout, setDoLogout] = useState(1)
  const token = getStorageDate('TOKEN')
  const isAuthenticated = !!token
  const userData = user as User
  const { notTrained } = useAuth(user)
  const navigate = useNavigate()
  useEffect(() => {
    subscriber.subscribe((val) => {
      setDoLogout(val)
    })
  }, [])

  useEffect(() => {
    if (doLogout === 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      signOut()
    }
  }, [doLogout])

  useEffect(() => {
    if (isAuthenticated) {
      getUserDetails().then((res) => {
        if (res.data.data) {
          res.data.data.is_training_completed = true
          res.data.data.is_training_enabled = true
        }
        setUser(res.data.data)
      })
    }
  }, [isAuthenticated])

  const setTrainingDataToStorage = (user: User) => {
    setTimeStamp(user?.videoBookmark?.timestamp!)
    setShipLimit(user.videoBookmark?.training_data?.ship_limit!)
  }

  async function signIn({ email, password }: SignInCredentials) {
    try {
      localStorage.clear()
      setLoadingUserData(true)
      const response = await api.post('/auth/login', { email, password })

      if (response?.data?.data?.user) {
        response.data.data.user.is_training_completed = true
        response.data.data.user.is_training_enabled = true
      }
      // eslint-disable-next-line camelcase
      const { token, refresh_token } = response.data.data
      setStorageData('TOKEN', token)
      setStorageData('REFRESH_TOKEN', refresh_token)
      setUser(response?.data?.data?.user)
      setTrainingDataToStorage(response?.data?.data?.user)
      setAuthorizationHeader(api.defaults, token)
      setLoadingUserData(false)
      if (notTrained) {
        navigate('/training')
      }
    } catch (error: any) {
      setLoadingUserData(false)
      if (error?.response?.data?.error) {
        notifyUser(error?.response?.data?.error?.message, 'danger')
      }
    }
  }

  async function updateBookmarkVideo() {
    if (getStorageDate('videoTime')) {
      try {
        await updateBookmark(getStorageDate('videoTime')!)
      } catch (err: any) {
        console.log(err)
      }
    }
  }
  function signOut(pathname = '/') {
    // setUser(null)
    deleteStorage()
    deleteData()
    window.location.pathname = pathname
  }

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      user: userData,
      loadingUserData,
      signIn,
      signOut,
      setUser,
      updateBookmarkVideo,
      timeStamp,
      shipLimit,
      sidebarOpen,
      setSidebarOpen
    }}>
      {children}
    </AuthContext.Provider>
  )
}
