import { ReactNode, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PageLoader from '../components/PageLoader/PageLoader'
import { AuthContext } from '../context/AuthContext'

interface IPrivateRoute {
  permissions?: string[]
  roles?: string[]
  redirectTo?: string
  children: ReactNode
}

// eslint-disable-next-line space-before-function-paren
export function PrivateRoute({
  permissions,
  roles,
  redirectTo = '/login',
  children
}: IPrivateRoute) {
  const { user, isAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if (user !== undefined) {
      if (location.pathname.includes('profile-image')) {
        navigate('/profile-image')
      }
      if (user.is_training_completed && !user.is_training_enabled) {
        navigate('/training')
      }
      if (!user?.is_training_completed && !user.is_training_enabled) {
        if (location.pathname.includes('training-completed')) {
          navigate('/training')
        } else if (location.pathname.includes('get-started')) {
          navigate('/get-started')
        } else if (user?.subscription?.status === 'active') {
          navigate('/training')
        }
      }

      if (user?.subscription?.status === 'active' && user?.is_training_completed && user.is_training_enabled) {
        if (location.pathname.includes('training')) {
          navigate('ship-sea')
        }
        if (location.pathname.includes('get-started')) {
          navigate('/ship-sea')
        }
        if (location.pathname.includes('training-completed')) {
          navigate('/training-completed')
        }
      }
    }
  }, [user])
  if (user === undefined) {
    return <PageLoader />
  } else {
    return children
  }
}
