import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { AuthContext } from '../../context/AuthContext'
import { SubscriptionContext } from '../../context/SubscriptionContext'
import Text from '../Text/Text'

const CouponPaymentForm = () => {
  const [subscribeLoading, setSubscribeLoading] = useState(false)
  const { createSubscriptionMethod, createNewSubscribe } = useContext(SubscriptionContext)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      couponCode: ''
    },
    validationSchema: Yup.object({
      couponCode: Yup.string()
        .required('Required')
    }),
    onSubmit: async (values) => {
      try {
        const coupon = values.couponCode
        setSubscribeLoading(true)
        if (user?.subscription?.status === 'canceled' || user?.subscription?.status === 'cancelling') {
          await createNewSubscribe(coupon)
        } else {
          await createSubscriptionMethod(coupon)
        }
        setSubscribeLoading(false)
        navigate('/get-started')
      } catch (error:any) {
        setSubscribeLoading(false)
      }
    }

  })
  return (
    <form onSubmit={handleSubmit}>
        <Grid item xs={12} lg={6} >
                <Text preset="label" text="COUPON CODE" />
                <TextField fullWidth value={values.couponCode} name="couponCode" onChange={handleChange} error={!!(touched.couponCode && errors.couponCode)}
                  helperText={touched.couponCode && errors.couponCode} />
              </Grid>
              <Button
                size="large"
                type="submit"
                className="form-submit-button"
              >
                {
                  subscribeLoading ? <CircularProgress /> : 'Finish'
                }
              </Button>
    </form>
  )
}

export default CouponPaymentForm
