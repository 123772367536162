import CircularProgress from '@material-ui/core/CircularProgress'
import { Avatar, Grid, Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { ChangeEvent, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { imageValidationSchema } from '../../components/Register/AuthValidation'
import Text from '../../components/Text/Text'
import { SubscriptionContext } from '../../context/SubscriptionContext'
import { uploadImage } from '../../services/uploads.service/upload'

interface InitialValues {
  files: File | null
}

const ImageUploader = () => {
  const { addUpdatePaymentMethod } = useContext(SubscriptionContext)

  const initialValues: InitialValues = {
    files: null
  }
  const [buttonLoading, setButtonLoading] = useState(false)

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: imageValidationSchema,
    onSubmit: (values) => {
      setButtonLoading(!buttonLoading)
      uploadImage(values.files).then(async (res) => {
        await addUpdatePaymentMethod(1)
        setButtonLoading(false)
        navigate('/ship-sea')
      })
    }
  })

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      formik.setFieldValue('files', file)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]
    if (file) {
      formik.setFieldValue('files', file)
    }
  }

  const handleFinish = async () => {
    setButtonLoading(true)
    await addUpdatePaymentMethod(1)
    setButtonLoading(false)
    navigate('/ship-sea')
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Text preset='eyebrow' text="Say Cheese" style={{ textAlign: 'center', marginBottom: 0 }} />
        <Text preset="h1" text="Upload a Photo" style={{ textAlign: 'center', marginTop: '0' }} />
        <input
          accept="image/*"
          id="image-input"
          type="file"
          onChange={handleImageChange}
          hidden
        />
        <div
          className='drop-area'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label htmlFor="image-input">
            {formik.values.files
              ? (
              <Avatar
                alt="Selected Image"
                src={URL.createObjectURL(formik.values.files)}
                className="avatar"
              />
                )
              : (
              <>
                <p className="text-center">CLICK TO UPLOAD OR DRAG PHOTO HERE</p>
              </>
                )}
          </label>
        </div>
        {formik.errors.files && (
          <div className="error light-red mt-10 text-center">{formik.errors.files}</div>
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Button
              size="large"
              className="skip-button"
              onClick={handleFinish}
            >
              Skip Upload
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} textAlign={'center'}>
            <Button
              size="large"
              type="submit"
              className="continue-button"
              disabled={!formik.isValid || buttonLoading}
            >{
              buttonLoading
                ? (
                <>
                  Uploading
                  <CircularProgress size={20} color="inherit" />
                </>
                  )
                : 'Continue'
            }
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ImageUploader
